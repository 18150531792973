import React, {useContext, useEffect, useState} from 'react';
import {ThemeContext} from 'styled-components';

import {ImageProps} from '@/types';

import {getNextMonthsFromDate} from '@/lib/dates';
import {buildArticleDescription, buildArticleLink, buildArticleSubTitle,} from '@/lib/buildArticleContent';
import Dropdown, {DropdownOption} from '../../atoms/Dropdown';
import Button from '../../atoms/Button';
import ArticleCard from '../../molecules/ArticleCard';
import Styled from './index.styled';

export type ArticleFilterProps = {
	title?: string;
	showFilterToggles: boolean;
	hideLoadMore?: boolean;
	articleTypePresetFilterToggle?: string;
	articleAuthorPresetFilterToggle?: string;
	articleLocationPresetFilterToggle?: string;
	articleTypeFilterToggles?: DropdownOption[]; // rename value to 'filter'/use both in DropdownOption
	articleAuthorFilterToggles?: DropdownOption[];
	articleLocationFilterToggles?: DropdownOption[];
	articleStyling?: string;
	subSite: string;
	anchorId: string;
};

type Article = {
	articleId: string;
	title: string;
	location: string;
	string: string;
	type: string;
	image: Pick<ImageProps, 'src' | 'alt'>;
	externalLinkUrl?: boolean;
	eventLink?: string;
};

const sortAlphabetically = filters => {
	filters.sort((a, b) => a.filter.localeCompare(b.filter));
};

const ArticleFilter = ({
												 anchorId,
												 articleAuthorFilterToggles,
												 articleAuthorPresetFilterToggle,
												 articleLocationFilterToggles,
												 articleLocationPresetFilterToggle,
												 articleStyling,
												 articleTypeFilterToggles,
												 articleTypePresetFilterToggle,
												 hideLoadMore,
												 showFilterToggles,
												 subSite,
												 title = 'Filter results by:'
											 }: ArticleFilterProps) => {
	const theme = useContext(ThemeContext);


	sortAlphabetically(articleTypeFilterToggles);
	sortAlphabetically(articleAuthorFilterToggles);
	sortAlphabetically(articleLocationFilterToggles);

	const ARTICLES_COUNT = 6;

	const [type, setType] = useState(articleTypePresetFilterToggle ?? 'all');
	const [author, setAuthor] = useState(articleAuthorPresetFilterToggle ?? 'all');
	const [location, setLocation] = useState(articleLocationPresetFilterToggle ?? 'all');
	const [eventsInMonth, setEventsInMonth] = useState('all');
	const [page, setPage] = useState(1);
	const [eventsView, setEventsView] = useState(false); // or have a flag come through to indicate if events should show

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<boolean>();
	const [articles, setArticles] = useState<Article[] | []>([]);
	const [hasMore, setHasMore] = useState(true);

	const loadArticles = async () => {
		const url = eventsView
			? new URL('/api/events', window.location.origin)
			: new URL('/api/articles', window.location.origin);

		if (eventsView) {
			if (eventsInMonth !== 'all') {
				url.searchParams.append('eventsInMonth', eventsInMonth);
			}
			url.searchParams.append('author', author);
			url.searchParams.append('location', location);
		} else {
			url.searchParams.append('type', type);
			url.searchParams.append('author', author);
			url.searchParams.append('location', location);
		}

		// eslint-disable-next-line no-unused-expressions
		page && url.searchParams.append('page', page.toString());

		setLoading(true);
		setError(false);

		try {
			const response = await fetch(url.toString());

			if (!response.ok) {
				throw new Error('Failed to search for articles');
			}

			const json = await response.json();

			setArticles([...articles, ...json.articles]);
			setPage(json.page);
			if (json.articles.length < ARTICLES_COUNT) {
				setHasMore(false);
			}
		} catch (e) {
			console.error(e);
			setError(true);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		loadArticles();
	}, [type, author, location, eventsView, eventsInMonth, page]);

	const handleChange = evt => {
		const { name } = evt.target;
		const { value } = evt.target;
		setArticles([]);
		setHasMore(true);
		switch (name) {
			case 'type':
				setType(value);
				break;
			case 'author':
				setAuthor(value);
				break;
			case 'location':
				setLocation(value);
				break;
			case 'date':
				setEventsInMonth(value);
				break;
			default:
				console.log('Unknown name');
				break;
		}
	};

	const handleToggle = currentType => {
		if (eventsView) {
			if (currentType === 'articles') {
				setArticles([]);
				setHasMore(true);
				setEventsView(false);
			}
		} else if (currentType === 'events') {
			setArticles([]);
			setHasMore(true);
			setEventsView(true);
		}
	};

	return (
		<>
			{showFilterToggles && (
				<Styled.Content__Filters id={anchorId || ''}>
					{title && <Styled.Content__Toggle_Title>{title}</Styled.Content__Toggle_Title>}
					<Styled.Content__Filters__Inner>
						<div>
							<Styled.Content__ToggleLabel>
								Toggle between
							</Styled.Content__ToggleLabel>
							<Styled.Content__Toggles>
								<Button
									buttonText="Articles"
									buttonType={eventsView ? 'tertiary' : 'primary'}
									callback={() => handleToggle('articles')}
								/>
								<Button
									buttonText="Events"
									buttonType={eventsView ? 'primary' : 'tertiary'}
									callback={() => handleToggle('events')}
								/>
							</Styled.Content__Toggles>
						</div>

						{eventsView && (
							<Dropdown
								id="date"
								label="Date"
								name="date"
								options={[{ displayText: 'All', filter: 'all' }].concat(
									getNextMonthsFromDate()
								)}
								onChange={handleChange}
							/>
						)}

						{!eventsView && !articleTypePresetFilterToggle && (
							<Dropdown
								id="type"
								label="Article type"
								name="type"
								options={articleTypeFilterToggles}
								onChange={handleChange}
							/>
						)}

						{!articleAuthorPresetFilterToggle && (
							<Dropdown
								id="author"
								label="Author"
								name="author"
								options={articleAuthorFilterToggles}
								onChange={handleChange}
							/>
						)}

						{!articleLocationPresetFilterToggle && (
							<Dropdown
								id="location"
								label="Location"
								name="location"
								options={articleLocationFilterToggles}
								onChange={handleChange}
							/>
						)}
					</Styled.Content__Filters__Inner>
				</Styled.Content__Filters>
			)}

			{!error && (
				<Styled.Content__Articles as="section">
					{!showFilterToggles && title && (
						<Styled.Content__Title>{title}</Styled.Content__Title>
					)}
					{articles.length > 0 && (
						<Styled.Content__Articles_Grid as="section">
							{articles.map((article: Article, index: number) => {
								if (eventsView) {
									return (
										<ArticleCard
											key={`${article.externalLinkUrl ||
												article.eventLink} + ${index}`}
											title={article.title}
											subtitle={buildArticleSubTitle(article)}
											description={buildArticleDescription(article)}
											link={buildArticleLink(article)}
											isExternalLink={!!article.externalLinkUrl}
											image={article.image}
											theme={theme}
											styling={articleStyling}
											subSite={subSite}
										/>
									);
								}
								return (
									<ArticleCard
										key={article.articleId + index}
										title={article.title}
										subtitle={buildArticleSubTitle(article)}
										description={`
											Published ${buildArticleDescription(article)}
											 ${!article.location ? ` - ${article.type}` : ''}
											`}
										link={`/${article.articleId}`}
										image={article.image}
										styling={articleStyling}
										subSite={subSite}
									/>
								);
							})}
						</Styled.Content__Articles_Grid>
					)}
					{!loading && articles.length === 0 && (
						<Styled.Content__Articles__Message>
							{`No ${
								eventsView ? 'Events' : 'Articles'
							} matched the selected filters`}
						</Styled.Content__Articles__Message>
					)}
				</Styled.Content__Articles>
			)}

			{error && (
				<Styled.Content__Articles as="section">
					<Styled.Content__Articles__Message>
						Oops, something went wrong. Please try again.
					</Styled.Content__Articles__Message>
				</Styled.Content__Articles>
			)}

			{hasMore && !hideLoadMore && (
				<Styled.Content_LoadMore as="section">
					<Button
						buttonText={!error ? 'Load more' : 'Try Again'}
						buttonType="tertiary"
						callback={() => {
							setPage(page + 1);
						}}
						loadingText="Loading"
						loading={loading}
					/>
				</Styled.Content_LoadMore>
			)}
		</>
	);
};

export default ArticleFilter;
